.projectDetail {
    min-height: 100vh;
    background: #f8f9fa;
    margin-bottom: 40px;
    overflow-x: hidden;
  }
  
  .hero {
    background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
    padding: 8rem 2rem 6rem;
    margin-bottom: 4rem;
    position: relative;
    overflow: hidden;
  }
  
  .heroOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%);
    z-index: 1;
  }
  
  .heroContent {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
  

.hero {
  position: relative;
  height: 400px;
  background-size: cover;
  background-position: center;
  color: white;
  margin-bottom: 40px;
}

.heroOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.heroContent {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 40px;
}

.breadcrumb {
  margin-bottom: 20px;
}

.breadcrumb a {
  color: #fff;
  text-decoration: none;
}

.breadcrumb a:hover {
  text-decoration: underline;
}

.content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 40px;
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.mainContent {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.videoContainer {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.videoContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.description h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.description p {
  line-height: 1.7;
  color: #333;
}

.gallery {
  display: grid;
  gap: 20px;
}

.gallery img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.gallery img:hover {
  transform: scale(1.02);
}

.sidebar {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.sidebarSection {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.sidebarSection h3 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #333;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.detailItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label {
  font-weight: 600;
  color: #555;
}

.authors {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.author {
  background-color: #e9ecef;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
}

.technologies {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag {
  background-color: #eef2ff;
  color: #4f46e5;
  padding: 5px 12px;
  border-radius: 20px;
  font-size: 14px;
}

.links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.link {
  display: block;
  padding: 10px 15px;
  background-color: #f1f5f9;
  color: #3b82f6;
  text-decoration: none;
  border-radius: 6px;
  transition: background-color 0.2s;
}

.link:hover {
  background-color: #e0e7ff;
}

@media (max-width: 768px) {
  .content {
    grid-template-columns: 1fr;
  }
  
  .hero {
    height: 300px;
  }
}

/* WorkPage.module.css */
.workPage {
  max-width: 1200px;
  margin: 0 auto;
}



.filterContainer {
  padding: 0 20px;
  margin-bottom: 30px;
}

.filterTabs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.filterTab {
  background: none;
  border: 1px solid #e2e8f0;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
  color: #4a5568;
}

.filterTab:hover {
  background-color: #f7fafc;
}

.filterTab.active {
  background-color: #4f46e5;
  color: white;
  border-color: #4f46e5;
}

.projectsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px;
  padding: 0 20px;
  margin-bottom: 60px;
}

.projectCard {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: white;
}

.projectCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.projectMedia {
  position: relative;
  height: 220px;
  overflow: hidden;
}

.projectMedia img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.projectCard:hover .projectMedia img {
  transform: scale(1.05);
}

.projectOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.projectCard:hover .projectOverlay {
  opacity: 1;
}

.projectOverlay a {
  color: white;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.projectOverlay a:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.projectInfo {
  padding: 20px;
}

.projectCategory {
  color: #6b7280;
  font-size: 14px;
  margin-bottom: 8px;
}

.projectTitle {
  font-size: 20px;
  margin-bottom: 10px;
  color: #1a202c;
}

.projectDescription {
  color: #4a5568;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.projectMetaInfo {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.projectMeta {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: #6b7280;
}

.metaIcon {
  font-size: 16px;
}

.projectFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  border-top: 1px solid #e2e8f0;
}

.projectAuthors {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.authorChip {
  background-color: #e9ecef;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #4a5568;
}

.projectTech {
  display: flex;
  gap: 8px;
}

.techTag {
  background-color: #eef2ff;
  color: #4f46e5;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 12px;
}

.loading, .error {
  text-align: center;
  padding: 40px;
  font-size: 18px;
  color: #718096;
}

.error {
  color: #e53e3e;
}

@media (max-width: 768px) {
  .projectsGrid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

.hero {
  position: relative;
  height: 300px;
  width: 100vw;
  margin-bottom: 2rem;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.heroOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%);
}

.heroContent {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2rem;
}

.heroContent h1 {
  font-size: 3rem;
  margin: 0;
  color: #ffffff;
  font-weight: bold;
}